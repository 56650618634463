var site = site || {};

site.quickshop = site.quickshop || {};
site.gotL2Cache = false;

(function ($) {
  if (typeof console == 'undefined') {
    this.console = { log: function () {} };
  }

  site.quickshop = {

    initAddToBag: function ($addLink, skuBaseId) {
      $addLink.unbind();
      $addLink.attr('data-skubaseid', skuBaseId);
      $addLink.on('click', function (e, args) {
        e.preventDefault();
        var $addtoBagButton = $(this);
        var buySkus = [];

        args = typeof args === 'undefined' ? {} : args;
        if (!$addLink.hasClass('multi-quickshop')) { // don't add to bag in cases where we need to iterate thru more than one product
          buySkus.push($addtoBagButton.attr('data-skubaseid'));
          if (typeof $addtoBagButton.data('secondarySkuBaseId') !== 'undefined') {
            buySkus.push($addtoBagButton.data('secondarySkuBaseId').toString());
            args.kitSkus = buySkus.join(',');
            args.kitType = buySkus.join('_').toString() + '_KIT';
            args.kitCat = '';
            args.collectionId = $addtoBagButton.data('collectionid') ? $addtoBagButton.data('collectionid') : false;
            args.cartId = $addtoBagButton.data('cartid') ? $addtoBagButton.data('cartid') : false;
            args.collectionName = $addtoBagButton.data('collectionname') ? $addtoBagButton.data('collectionname') : false;
          }
          args.qty = parseInt($addtoBagButton.data('qty')) || 1;
          args.REPLENISHMENT_FREQ = $addtoBagButton.data('freq') || false;
          args.skuBaseId = buySkus;
          site.addToCart(args);
          var loadingParams = {
            target: this,
            'matchTarget': ['display', 'height', 'width'],
            'css': {
              'text-align': 'center',
              'vertical-align': 'top'
            }
          };

          if (args && args.fromStickyNav) {
            loadingParams.target = args.button;
            loadingParams.matchTarget.push('float', 'margin');
          }
          var loadingId = site.loading.show(loadingParams);

          $(document).one('addToCart.success addToCart.failure', function (r) {
            site.loading.hide(loadingId);
          });
        } else {
          $(document).trigger('multi-quick', { 'skuBaseId': $(this).attr('data-skubaseid') });
        }
        if (Drupal.cl.hasPersonalization()) {
          if (site.profile && site.profile.products) {
            $(document).one('addToCart.success', function () {
              site.profile.products.quickshop.send(skuBaseId);
            });
          }
        }
      });
    },
    initAddToFavorites: function ($addLink, $qs, skuBaseId) {
      var $qs = $qs || [];

      $addLink.unbind();
      $addLink.attr('data-skubaseid', skuBaseId);
      $addLink.on('click', function (e) {
        e.preventDefault();
        site.favorites.manipulateWishList({
          skuBaseId: $(this).attr('data-skubaseid'),
          successCallback: function (r) {
            if ($qs) {
              var $message = $('.icon-heart-message', $qs);
              var $heart = $('.icon-heart', $qs);

              if ($message.length) {
                $heart.addClass('active');
                $message.addClass('active');
                if (r.SUCCESS == 1 && r.KEY != 'SKU_ALREADY_IN_COLLECTION.ADD_SKU.COLLECTION.SAVE') {
                  $message.addClass('favorites-add-success');
                  $message.removeClass('favorites-add-exists');
                } else if (r.KEY == 'SUCCESS.ADD_SKU.COLLECTION.SAVE') {
                  $message.addClass('favorites-add-success');
                  $message.removeClass('favorites-add-exists');
                } else if (r.KEY == 'SKU_ALREADY_IN_COLLECTION.ADD_SKU.COLLECTION.SAVE') {
                  $message.removeClass('favorites-add-success');
                  $message.addClass('favorites-add-exists');
                }
              }
            }

            // Redirect the user to either signin or favorites page if the favorites added popover is clicked
            $('.icon-heart-message').once('icon-heart-message').on('click', function(e) {
              var favorites = $(this).children('span:visible');
              var links = favorites.children('a');
              var redirectURL;

              if (links.filter('.signin').length == 0) {
                redirectURL = links.filter('.favorites').attr('href');
              } else {
                redirectURL = links.filter('.signin').attr('href');
              }
              window.location.href = redirectURL;
            });
            if (Drupal.cl.hasPersonalization()) {
              if (site.profile && site.profile.products) {
                site.profile.products.quickshop.send(skuBaseId);
              }
            }
          }
        });
      });
    },

    /**
     * Get the Quickshop HTML and initialize the various internal components
     */
    render: function (args) {
      var self = this;
      var show_reviews = typeof args.showReviews === 'undefined' ? true : args.showReviews;
      // fire QV tagging event
      var QV_prod_id = typeof window.CATEGORY_ID !== 'undefined' ? window.CATEGORY_ID + '~' + args.productData.PRODUCT_ID : args.productData.PRODUCT_ID;

      $(window).trigger('MPP:productQV', [QV_prod_id]);

      return Drupal.cl.template.get({
        path: 'quickshop',
        object: args.productData,
        callback: function (html) {
          $qs = $(html);
          self.updateSkinTypeList(args.productData, $qs);
          self.setUpSkuSelection(args.productData, $qs);
          // make sure reviews are wanted before making that call...
          if (show_reviews) {
            self.setUpReview(args, $qs);
          }

          // Close button event handler
          $qs.find('.close').once('qs-overlay-close').on('click', function(e) {
            e.preventDefault();
            $qs.slideUp();
            // add global trigger to be used for post-close functions
            $(document).trigger('qs-overlay-close');
            $('.caret').addClass('hidden');
          });

          $qs.on('selectSku', function (i, sku_args) {
            var sku = $(args.productData.skus).filter(function () {
              return this.SKU_BASE_ID.toString() === sku_args.SKU_BASE_ID.toString();
            })[0];

            if (typeof sku != 'undefined') {
              site.quickshop.setSelectSku(sku, $qs);
            }
            var $message = $('.icon-heart-message', $qs);

            $message.removeClass('active');
          });

          // sku purge and default selection
          if (site.gotL2Cache) {
            site.quickshop.setDefaultSkuSelection(args.productData, $qs);
          }

          // trigger sku purge and default selection when getting the L2 prodcache status
          $(document).on('inventory_status_stored', function () {
            site.quickshop.setDefaultSkuSelection(args.productData, $qs);
          });

          return $qs;
        }
      });
    },

    setDefaultSkuSelection: function (productData, $qs) {
      var undef;
      var count_valid = 0;

      if ((productData.skus !== undef) &&
          (productData.skus[0] !== undef) &&
          (productData.skus[0].SKU_BASE_ID !== undef)) {
        var sku;
        var filteredSkus = [];

        if (site.quickshop.customSwatches && site.quickshop.customSwatches[productData.PRODUCT_ID]) {
          // Filter out skus that match personalization rules.
          var customSwatches = site.quickshop.customSwatches[productData.PRODUCT_ID];

          $(customSwatches).each(function () {
            var swatchId = this.SKU_ID;

            $(productData.skus).each(function () {
              if (swatchId == this.SKU_ID) {
                filteredSkus.push(this);
              }
            });
          });
        }

        var filteredData = filteredSkus[0] ? filteredSkus : productData.skus;

        for (var i = 0; i < filteredData.length; i++) {
          var o_sku = $(document).data(filteredData[i].SKU_ID);

          if ((o_sku !== undef) && ((o_sku.isOrderable == 1) || (o_sku.INVENTORY_STATUS == 3) || (o_sku.INVENTORY_STATUS == 2) || (o_sku.INVENTORY_STATUS == 7))) {
            if (sku === undef) {
              sku = o_sku;
              $qs.trigger('selectSku', {
                SKU_BASE_ID: sku.SKU_BASE_ID
              });
            }
            count_valid++;
          } else {
            // console.log('removing: ' + productData.skus[i].SKU_ID + ' because it is unshoppable. (not "isOrderable" or "coming soon")');
            $('[data-skubaseid="' + productData.skus[i].SKU_BASE_ID + '"], [data-skuid="' + productData.skus[i].SKU_ID + '"]', $qs).remove();
            $('[rel="' + productData.skus[i].SKU_BASE_ID + '"]', $qs).parent().remove();
            // Removing from the sticky bar
            $('div.sticky_prod_select a[rel="' + productData.skus[i].SKU_BASE_ID + '"]').parent().remove();
          }
        }
      }
      $('.shade-chooser li:empty').remove();
      // don't show "choose option" area if only one option available...
      if (count_valid == 1) {
        var sized = $qs.find('.sizes').length;

        if (sized) {
          $qs.find('.price .formatted_price').append(' ');
          $qs.find('.price .formatted_price').append($qs.find('.sizes ul.quickshop-sizes li a').html());
        }
        $('.sizes, .skin-type', $qs).addClass('hidden');
        $('#sticky-bar .sticky_prod_select .dropdown').addClass('hidden');
      }

      // pass product data to event method.
      if (Drupal.cl.hasPersonalization()) {
        if (site.profile && site.profile.products) {
          site.profile.products.quickshop.init(productData);
        }
      }
    },

    setUpSkuSelection: function (productData, $qs) {
      // set up select dropdown & shade boxes if shaded
      if (productData.shaded) {
        this.setUpSelectBox($qs);
        this.setUpShadeBoxes(productData, $qs);
      }
      // set up select size or skin type options
      else {
        this.setUpOtherOptions($qs);
      }
      // TEMP clean up styled descriptions coming from prodcache
      $('.abstract [style]').attr('style', '');
    },

    // Set everything that needs to be updated when a sku gets selected here...
    setSelectSku: function (sku, $qs, args) {
      args = args || {};
      // shades...
      if (sku.SHADENAME != null) {
        // $('.shade-info-name', $qs).html(sku.SHADENAME);
        $('.sbSelector', $qs).html(sku.SHADENAME);
        $('.sticky_content .sbSelector').html(sku.SHADENAME);
        $('.shade-chooser div[data-skubaseid]', $qs).removeClass('active');
        $('.shade-chooser div[data-skubaseid="' + sku.SKU_BASE_ID + '"]', $qs).addClass('active');
        $('.shade-info-name', $qs).html(sku.SHADENAME);
        $('.shade-info-name', $qs).parent().toggleClass('hidden', (sku.SHADENAME == '') || (sku.SHADENAME == null));
        $('.shade-info-desc', $qs).html(sku.SHADE_DESCRIPTION);
        $('.shade-info-desc', $qs).parent().toggleClass('hidden', (sku.SHADE_DESCRIPTION == '') || (sku.SHADE_DESCRIPTION == null));
        $('.shade-info-finish', $qs).html(sku.FINISH);
        $('.shade-info-finish', $qs).parent().toggleClass('hidden', (sku.FINISH == '') || (sku.FINISH == null));
        $('.shade-info-color_group', $qs).html(sku.ATTRIBUTE_COLOR_FAMILY);
        $('.shade-info-color_group', $qs).parent().toggleClass('hidden', (sku.ATTRIBUTE_COLOR_FAMILY == '') || (sku.ATTRIBUTE_COLOR_FAMILY == null));
        $('.shade-info-undertone', $qs).html(sku.UNDERTONE);
        $('.shade-info-undertone', $qs).parent().toggleClass('hidden', (sku.UNDERTONE == '') || (sku.UNDERTONE == null));
        if (site.quickshop.isMultiShade(sku)) {
          this.populateMultiShades(sku, $qs);
          this.populateMultiShades(sku, $('.sticky_content'));
          $('.shade_icon:not(.sbOptions .shade_icon)', $qs).remove();
          $('.shade_icon:not(.sbOptions .shade_icon)', $('.sticky_content')).remove();
          var shade_icon_html = $('.sbOptions [rel="' + sku.SKU_BASE_ID + '"]').siblings('.shade_icon').html();

          $('.shade-dd .sbHolder', $qs).prepend($('<div class="shade_icon" />').html(shade_icon_html));
          $('.shade-dd .sbHolder', $('.sticky_content')).prepend($('<div class="shade_icon" />').html(shade_icon_html));
        } else {
          $('.smoosh-wrapper', $qs).css('background-color', sku.HEX_VALUE_STRING);
          $('.sbHolder', $qs).css('background-color', sku.HEX_VALUE_STRING);
          $('.sbHolder', $('.sticky_content')).css('background-color', sku.HEX_VALUE_STRING);
          $('.smoosh-panel-0 img', $qs).attr('src', '/media/export' + sku.SMOOSH_PATH_STRING).parent().removeClass('hidden');
        }
      }
      // set possible selections from sized or skin-type options
      $('ul.quickshop-options a[data-skubaseid]', $qs).removeClass('active');
      $('ul.quickshop-options a[data-skubaseid]', $('.sticky_content')).removeClass('active');
      $('ul.quickshop-options a[data-skubaseid="' + sku.SKU_BASE_ID + '"]', $qs).addClass('active');
      $('ul.quickshop-options a[data-skubaseid="' + sku.SKU_BASE_ID + '"]', $('.sticky_content')).addClass('active');
      // price display
      // if you're on the SPP, and another QS (from power couples or similar) attempts to select the sku, restrict the price update to just the QS window
      if ($('body').hasClass('pc_spp_view') && !args.isSppProduct) {
        $qs.find('.price .formatted_price').html(sku.formattedPrice);
      } else { // otherwise, feel free to change the price everywhere as before
        $('.price .formatted_price').html(sku.formattedPrice);
      }
      // set buy and favorites buttons
      site.quickshop.initAddToBag($('.btn-add-to-bag', $qs), sku.SKU_BASE_ID);
      site.quickshop.initAddToFavorites($('.icon-heart', $qs), $qs, sku.SKU_BASE_ID);
      // check inventory status
      var latest_status_sku = $(document).data(sku.SKU_ID);

      site.quickshop.setBuyButtonStatus(latest_status_sku, $qs);
      // swap sku image if possible
      site.quickshop.swapSkuImage(sku);
      // set replenishment message
    },

    setUpSelectBox: function ($qs) {
      $('select.selectbox', $qs).selectbox({
        onOpen: function (inst) {
          // console.log("open", inst);
          $('.sbHolder a', $qs).on('click', function(e) {
            e.preventDefault();
          });
          $(this).parent().find('ul.sbOptions').css('overflow', 'auto');
        },
        onClose: function (inst) {
          // console.log("close", inst);
        },
        onChange: function (val, inst) {
          // console.log("change", inst);
          var prod_id = $(inst.input[0]).attr('data-productid');

          $qs.trigger('selectSku', {
            PRODUCT_ID: prod_id,
            SKU_BASE_ID: val
          });
        },
        effect: 'slide'
      });
      $('.sbOptions li a', $qs).each(function () {
        var bgcolor = $('option[value="' + $(this).attr('rel') + '"]', $qs).attr('data-skushade');
        var num_of_shades = bgcolor.split(',').length;

        if (num_of_shades > 1) { // is multi-sku
          var shade_icon = $('<div class="shade_icon" />');

          for (var i = 0; i < num_of_shades; i++) {
            shade_icon.append('<div class="multi-' + num_of_shades + '" style="background-color: ' + bgcolor.split(',')[i] + ';"></div>');
            // use this to properly display the shade boxes
            $('.shade-chooser div[data-skushade="' + bgcolor + '"]', $qs)
              .append('<div class="multi-' + num_of_shades + '" style="background-color: ' + bgcolor.split(',')[i] + ';"></div>')
              .css('background-color', 'none');
          }
          $(this).parent()
            .prepend(shade_icon)
            .addClass('multi-shade');
        } else {
          $(this).parent().css('background-color', bgcolor);
        }
      });
    },

    setSpecialOptions: function () {
      var params = Drupal.cl.getQSParams();

      if (params == null) {
        return;
      }
      if (params.OE != undefined) {
        if (page_data != null && page_data['catalog-spp'] != null && page_data['catalog-spp']['products'] != null) {
          site.quickshop.selectOnlineExclusives(page_data['catalog-spp']['products'][0]);
          $('.sp-options-all').attr('href', "javascript:site.quickshop.selectAllProds(page_data['catalog-spp']['products'][0]);");
          $('.sp-options-oe').attr('href', "javascript:site.quickshop.selectOnlineExclusives(page_data['catalog-spp']['products'][0]);");
          $('.sp-options').show();
        }
      }
    },

    selectAllProds: function (productData) {
      var prodDataSlice = jQuery.extend(true, {}, productData);
      var context = $('.module-spp-detail');
      var qs = site.quickshop;

      $('.sp-options-oe').css('font-weight', 'normal');
      $('.sp-options-all').css('font-weight', 'bold');

      $('.shade-chooser div[data-skushade]', context).each(function (i, box) {
        $(box).show();
      });
      qs.updateSelectBoxes(prodDataSlice);
    },

    selectOnlineExclusives: function (productData) {
      var prodDataSlice = jQuery.extend(true, {}, productData);
      var qs = site.quickshop;
      var skus = prodDataSlice.skus;
      var context = $('.module-spp-detail');
      var exclusiveSkus = new Array;

      $('.sp-options-oe').css('font-weight', 'bold');
      $('.sp-options-all').css('font-weight', 'normal');

      for (var i = 0; i < skus.length; i++) {
        if (skus[i]['SHADE_DESCRIPTION'] == null || skus[i]['SHADE_DESCRIPTION'].match(/Online Exclusive/ig) == null) {
          skus.splice(i, 1);
          i--;
        } else {
          exclusiveSkus.push(skus[i]);
        }
      }

      $('.shade-chooser div[data-skushade]', context).each(function (i, box) {
        var show = false;

        $.each(exclusiveSkus, function (k, sku) {
          if ($(box).attr('data-skubaseid') == sku.SKU_BASE_ID) {
            show = true;

            return;
          }
        });
        if (show == false) {
          $(box).hide();
        }
      });

      prodDataSlice.skus = skus;

      qs.updateSelectBoxes(prodDataSlice);
      qs.setSelectSku(exclusiveSkus[0], context);
    },

    updateSelectBoxes: function (productData) {
      var context = $('.module-spp-detail');
      var skus = productData.skus;
      var opts = $('.sbOptions');
      var showSkus = new Array;

      for (var i = 0; i < skus.length; i++) {
        showSkus[skus[i].SKU_BASE_ID] = true;
      }

      for (var i = 0; i < opts.length; i++) {
        var opt = opts[i];
        var items = $(opt).find('li');

        for (var k = 0; k < items.length; k++) {
          item = items[k];
          o = $(item).find('a');
          if (!o.length) {
            break;
          }
          showSkus[o.attr('rel')] ? $(item).show() : $(item).hide();
        }
      }
    },

    setUpShadeBoxes: function (productData, $qs) {
      $('.shade-chooser div[data-skushade]', $qs).each(function (i, box) {
        var sku = $(productData.skus).filter(function () {
          return this.SKU_BASE_ID.toString() === $(box).attr('data-skubaseid');
        })[0];

        $(box)
          .on('mouseover', function () {
            // we are a single shade sku so change the color
            if (sku.HEX_VALUE_STRING.split(',').length === 1) {
              $('.smoosh-panel-0 img', $qs).attr('src', '/media/export' + sku.SMOOSH_PATH_STRING).parent().removeClass('hidden');
              $('.smoosh-wrapper', $qs).css('backgroundColor', $(this).attr('data-skushade'));
            }
            // we are a multi shade sku so change the shades
            else {
              site.quickshop.populateMultiShades(sku, $qs);
            }
            $('.shade-info-name', $qs).html(sku.SHADENAME);
            $('.shade-info-desc', $qs).html(sku.SHADE_DESCRIPTION);
            $('.shade-info-finish', $qs).html(sku.FINISH);
            $('.shade-info-color_group', $qs).html(sku.ATTRIBUTE_COLOR_FAMILY);
            $('.shade-info-undertone', $qs).html(sku.UNDERTONE);
          })
          .on('mouseout', function () {
            if (sku.HEX_VALUE_STRING.split(',').length === 1) {
              $('.smoosh-wrapper', $qs).css('backgroundColor', $('.shade-chooser div.active[data-skushade]').attr('data-skushade'));
            }
            var select_sku = $(productData.skus).filter(function () {
              return this.SKU_BASE_ID.toString() === $('.btn-add-to-bag', $qs).attr('data-skubaseid');
            })[0];
            var undef;

            if (select_sku !== undef) {
              $('.shade-info-name', $qs).html(select_sku.SHADENAME);
              $('.shade-info-desc', $qs).html(select_sku.SHADE_DESCRIPTION);
              $('.shade-info-finish', $qs).html(select_sku.FINISH);
              $('.shade-info-color_group', $qs).html(select_sku.ATTRIBUTE_COLOR_FAMILY);
              $('.shade-info-undertone', $qs).html(select_sku.UNDERTONE);
            }
          })
          .on('click', function () {
            $qs.trigger('selectSku', { SKU_BASE_ID: $(this).attr('data-skubaseid') });
          });
      });
      if ($('.shade-chooser div[data-skushade]', $qs).length < 20) {
        $('.shade-chooser', $qs).addClass('big');
      }
    },

    setUpOtherOptions: function ($qs) {
      $('ul.quickshop-options a[data-skubaseid]', $qs).each(function (i, size_option) {
        $(size_option).on('click', function (e) {
          e.preventDefault();
          $qs.trigger('selectSku', { SKU_BASE_ID: $(this).attr('data-skubaseid') });
        });
      });
    },

    setUpReview: function (args, $qs) {
      var PROD_BASE_ID = args.productData.PRODUCT_ID.replace(/[A-Za-z$-]/g, '');

      // get review data if not included
      if (typeof args.productData.review == 'undefined') {
        generic.jsonrpc.fetch({
          method: 'reviews.latestMppProductReview',
          params: [{ 'PROD_BASE_ID': PROD_BASE_ID }],
          onSuccess: function (r) {
            var resultData = r.getValue();

            site.quickshop.renderReview(resultData, $qs);
            $(document).trigger('qs.review');
          },
          onFailure: function (r) {
            console.log(r);
            $(document).trigger('qs.review');
          }
        });
      } else {
        site.quickshop.renderReview(args.productData.review, $qs);
      }
    },

    renderReview: function (review, $qs) {
      if (review !== null) {
        $('.reviews h3', $qs).html(review.TITLE);
        $('.reviews h4 .user', $qs).html(review.DISPLAY_NAME);
        if (review.REVIEW_TEXT !== null) {
          // trim REVIEW_TEXT if longer than 400 characters, add ellipsis
          var reviewText;

          if (review.REVIEW_TEXT.length < 400) {
            reviewText = review.REVIEW_TEXT;
          } else {
            reviewText = review.REVIEW_TEXT.substring(0, 400) + '...';
          }
          $('.reviews .review_comment', $qs).html('“' + reviewText + '”');
        } else { // if no comment, remove "so-and-so says:"
          $('.reviews h4', $qs).html('');
        }
        if (review.PRO_TAGS !== null) {
          $('.reviews .pros strong', $qs).html(review.PRO_TAGS);
        } else { // if no pros given, hide "Pros" title
          $('.reviews .pros', $qs).html('');
        }
        $('.col2.review', $qs).removeClass('hidden');
      }
    },

    setBuyButtonStatus: function (sku, $qs) {
      var undef;

      if (sku === undef) {
        $('.btn-add-to-bag', $qs).css('display', 'none');
      } else {
        $('.spp_product_status div', $qs).addClass('hidden');
        if (site.productData.isComingSoon(sku) || site.productData.isTempOutOfStock(sku)) {
		  var bis_request_type = 'BIS';

          if (site.productData.isTempOutOfStock(sku)) {
            $('.temp_out_of_stock_msg', $qs).removeClass('hidden');
          } else {
			      $('.coming_soon_stock_msg', $qs).removeClass('hidden');
				  bis_request_type = 'CS';
		  }
		  $('.temp_out_of_stock_msg a,.coming_soon_stock_msg a', $qs).on('click', function(e, link) {
            e.preventDefault();
            $(link).unbind();
            var file = '/waitlist/signup.tmpl?SKU_BASE_ID=' + sku.SKU_BASE_ID + '&REQUEST_TYPE=' + bis_request_type;
            var options = {
				  includeBackground: false,
				  includeCloseLink: true,
				  hideOnClickOutside: true,
				  cssStyle: {
                border: '1px solid #5dba98',
                width: 'auto',
                height: 'auto',
                padding: '0'
              }
            };

            options.content = '<iframe style="padding:33px 20px 0"  width="360px" height="210px" scrolling="no" frameborder="0" src="' + file + '"></iframe>';
            generic.overlay.launch(options);
		  });
        } else if (site.productData.isInactive(sku)) {
          $('.inactive_stock_msg', $qs).removeClass('hidden');
        } else if (site.productData.isSoldOut(sku)) {
          $('.sold_out_stock_msg', $qs).removeClass('hidden');
        }
        // hide or show buy button
        if (sku.isOrderable == 1) {
          $('.btn-add-to-bag', $qs).css('display', 'inline-block');
        } else {
          $('.btn-add-to-bag', $qs).css('display', 'none');
        }
      }
    },

    isMultiShade: function (sku) {
      var hexAr = sku.HEX_VALUE_STRING.split(',');

      return hexAr.length > 1;
    },

    swapSkuImage: function (sku) {
      if ($('img[data-skuid="' + sku.SKU_ID + '"]').length > 0) {
        var $sku_img = $('img[data-skuid="' + sku.SKU_ID + '"]');

        if ($sku_img.height() === 464) {
          $('.page-spp img.full.product-img').attr('src', $sku_img.attr('src'));
        }
      }
      $('.page-spp img.full.product-img').removeClass('hidden');
    },

    populateMultiShades: function (sku, $qs) {
      $('.single-smoosh', $qs).addClass('hidden');
      var is_quad = sku.HEX_VALUE_STRING.split(',').length === 4;
      var is_trio = sku.HEX_VALUE_STRING.split(',').length === 3;

      for (var i = 0; i < sku.HEX_VALUE_STRING.split(',').length; i++) {
        $('.smoosh-panel-' + i, $qs).css('background-color', sku.HEX_VALUE_STRING.split(',')[i]);
        $('.smoosh-panel-' + i + ' img', $qs)
          .attr('src', '/media/export' + sku.SMOOSH_PATH_STRING.split(',')[i])
          .parent().removeClass('hidden');
      }
      $('.smoosh-wrapper', $qs).css('background-color', 'none');
      if (is_quad) { // four colors
        $('.quad-smoosh', $qs).removeClass('hidden');
      } else if (is_trio) { // three colors
        $('.trio-smoosh', $qs).removeClass('hidden');
      } else { // two colors
        $('.duo-smoosh', $qs).removeClass('hidden');
      }
    },

    updateSkinTypeList: function (productData, $qs) {
      $qs.find('.skin-types-top .list').each(function (i, skin_type_list) {
        var skinTypeList = $(skin_type_list).html().split(',');

        if ($(skin_type_list).html() === 'I, II, III, IV') {
          $(skin_type_list).html($('[data-localtrans="all_skin_types"]:first').html());
        } else {
          var expandedSkinTypes = '';

          $(skinTypeList).each(function (i, skin_type) {
            var s_type = $.trim(skin_type);

            expandedSkinTypes += s_type == 'I' ? ', 1 - ' + $('[data-localtrans="very_dry_dry"]:first').html() :
              s_type == 'II' ? ', 2 - ' + $('[data-localtrans="dry_combination"]:first').html() :
              s_type == 'III' ? ', 3 - ' + $('[data-localtrans="combination_oily"]:first').html() : ', 4 - ' + $('[data-localtrans="oily"]:first').html();
          });
          if (expandedSkinTypes.length > 2) {
            expandedSkinTypes = expandedSkinTypes.substring(2);
            $qs.find('.skin-types-top .list').html(expandedSkinTypes);
          }
        }
      });
      // Check to see if this item should be displaying the skin type options
      // rather than the size options
      var undef;

      if ((productData.skus !== undef) &&
          (productData.skus[0] !== undef) &&
          (productData.skus[0].STRENGTH !== undef) &&
          (productData.skus[0].STRENGTH !== null)) {
        $('ul.quickshop-type li a', $qs).wrapInner('<div class="label"></div>');
        $('ul.quickshop-type li', $qs).each(function (j, list_item) {
          var $a = $('a', $(list_item));
          var sku_base_id = $a.attr('data-skubaseid');
          var sku = $(productData.skus).filter(function () {
            return this.SKU_BASE_ID.toString() === sku_base_id;
          })[0];

          for (var i = 6; i > 1; i--) {
            if (sku.SKIN_TYPE_TEXT.charAt(i) == 1) {
              $a.prepend('<span class="type type-' + (i - 1) + '">' + (i - 1) + '</span>');
            }
          }
        });
        $('.sizes', $qs).addClass('hidden');
        $('.skin-type:not(.sticky_content .skin-type)', $qs).removeClass('hidden');
        site.quickshop.equalizeOptionHeight($qs);
      } else if ((productData.skus !== undef) &&
          (productData.skus[0] !== undef) &&
          (productData.skus[0].SKIN_TYPE_TEXT !== undef) &&
          (productData.skus[0].SKIN_TYPE_TEXT !== null)) {
        var skinTypes = '';
        var hasDups = false;

        $(productData.skus).each(function (i, sku) {
          $('.skin-type a[data-skubaseid="' + sku.SKU_BASE_ID + '"]', $qs).html('');
          var expandedSkinTypes = '';
          var expandedSkinTypeLabel = '';

          for (var i = 2; i < 6; i++) {
            if (sku.SKIN_TYPE_TEXT.charAt(i) == 1) {
              // switching from localized text to just numbers per Lauren Castellanos request 6.16
              expandedSkinTypes += i == 2 ? ', ' + (i - 1) : // $('[data-localtrans="very_dry_dry"]:first').html() :
                i == 3 ? ', ' + (i - 1) : // $('[data-localtrans="dry_combination"]:first').html() :
                i == 4 ? ', ' + (i - 1) // $('[data-localtrans="combination_oily"]:first').html() 
                : ', ' + (i - 1); // $('[data-localtrans="oily"]:first').html();
              expandedSkinTypeLabel += i == 2 ? ', ' + $('[data-localtrans="very_dry_dry"]:first').html() :
                i == 3 ? ', ' + $('[data-localtrans="dry_combination"]:first').html() :
                i == 4 ? ', ' + $('[data-localtrans="combination_oily"]:first').html()
                : ', ' + $('[data-localtrans="oily"]:first').html();
              $('.skin-type a[data-skubaseid="' + sku.SKU_BASE_ID + '"]', $qs)
                .append('<span class="type type-' + (i - 1) + '">' + (i - 1) + '</span>');
            }
          }
          if (skinTypes.indexOf(expandedSkinTypes) !== -1) {
            hasDups = true;
          }
          skinTypes += expandedSkinTypes;
          expandedSkinTypes = expandedSkinTypes.length > 2 ? expandedSkinTypes.substring(2) : expandedSkinTypes;
          expandedSkinTypeLabel = expandedSkinTypeLabel.length > 2 ? expandedSkinTypeLabel.substring(2) : expandedSkinTypeLabel;
          $('.skin-type a[data-skubaseid="' + sku.SKU_BASE_ID + '"]', $qs).append('<div class="label">' + expandedSkinTypeLabel + '</div>');
          if (expandedSkinTypes !== '') {
            expandedSkinTypes = expandedSkinTypes === '1, 2, 3, 4' ? $('[data-localtrans="all_skin_types"]:first').html() : expandedSkinTypes;
            $qs.find('.skin-types-top .list')
              .html(expandedSkinTypes)
              .parent().removeClass('hidden');
          }
        });
        if (!hasDups) {
          $('.sizes', $qs).addClass('hidden');
          $('.skin-type:not(.sticky_content .skin-type)', $qs).removeClass('hidden');
          // $('<div class="skin-types-top"><span class="term">Skin Types: </span><span class="list">'+skinTypes.substring(2)+'</span></div>').insertAfter('.col1 .prod-hd, .col2 h1', $qs);
        }
        site.quickshop.equalizeOptionHeight($qs);
      }
    },

    equalizeOptionHeight: function ($qs) {
      var maxHeight = 65;

      $('ul.quickshop-type li a', $qs).each(function () {
        maxHeight = maxHeight < $(this).height() ? $(this).height() : maxHeight;
      });
      maxHeight += 10;
      $('ul.quickshop-type li a', $qs).css('height', maxHeight + 'px');

      $('ul.quickshop-sizes li a', $qs).each(function () {
        maxHeight = maxHeight < $(this).height() ? $(this).height() : maxHeight;
      });
      maxHeight += 10;
      $('ul.quickshop-sizes li a', $qs).css('height', maxHeight + 'px');
    },

    truncateProdDesc: function (qs) {
      var desc = qs.find('.prod-desc');
      var readMoreLink = desc.find('a');

      if ($(desc)[0].scrollHeight > desc.height()) {
        var ellipsis = document.createElement('span');

        ellipsis.innerHTML = '... ';

        while ($(desc)[0].scrollHeight > desc.height()) {
          desc.find(ellipsis).remove();
          var theText = desc.contents(':not(a, span)').text();

          theText = $.trim(theText);
          theText = theText.substring(0, theText.length - 2);
          desc.html(theText);
          desc.append(ellipsis);
          desc.append(readMoreLink);
        }
      }
    },

    setReplenishmentMessage: function (sku, $qs) {}
  };

  $(document).on('inventory_status_stored', function () {
    site.gotL2Cache = true;
  });

  // check for special options, set via the querystring.
  $(window).on('load', function() {
    site.quickshop.setSpecialOptions();
  });
})(jQuery);
